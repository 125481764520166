<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h2>{{$t('tools/email-title')}}</h2>
    </div>
    <div class="ibox-content p-md">

      <form role="form" @submit="sendEmail" name="sendEmailForm">
       
        <div class="form-group row">
          <label for="emailAccount" class="col-lg-3 col-form-label">Account</label>
          <div class="col-lg-9">
            <v-select :options="listEMailAccounts" v-model="form.account" id="emailAccount" placeholder="Account">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.account"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
        </div>

        <div class="form-group row">
          <label for="subject" class="col-lg-3 col-form-label">Subject</label>
          <div class="col-lg-9">
            <input id="subject" type="text" placeholder="Subject" class="form-control" v-model="form.subject" required />
          </div>
        </div>

        <div class="form-group row">
          <label for="to" class="col-lg-3 col-form-label">To</label>
          <div class="col-lg-9">
            <input id="to" type="text" placeholder="To" class="form-control" v-model="form.to" required />
          </div>
        </div>

        <div class="form-group row">
          <label for="cc" class="col-lg-3 col-form-label">Cc</label>
          <div class="col-lg-9">
            <input id="cc" type="text" placeholder="Cc" class="form-control" v-model="form.cc" />
          </div>
        </div>

        <div class="form-group row">
          <label for="bcc" class="col-lg-3 col-form-label">Bcc</label>
          <div class="col-lg-9">
            <input id="bcc" type="text" placeholder="Bcc" class="form-control" v-model="form.bcc" />
          </div>
        </div>

        <div class="form-group row">
          <label for="content" class="col-lg-3 col-form-label">Content</label>
          <div class="col-lg-9">
            <textarea id="content" type="text" placeholder="Content" class="form-control" v-model="form.content" required />
          </div>
        </div>

        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">Send</button>
      </form>

    </div>
  </div>
</template>

<style scoped>
  
</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

@Component({
  components: {}
})
export default class Email extends Vue {

  form:any = {
    account: null,
    subject: "Test subject",
    to: "nicolasigot@gmail.com",
    cc: "",
    bcc:"",
    content: "Test content"
  };

  listEMailAccounts:string[] = [];

  laddaSubmit:Ladda.LaddaButton|null = null;
  
  created() {
    this.updateListEmailAccounts();
  }

  mounted() {
    var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=sendEmailForm] button.ladda-button' );
    this.laddaSubmit = Ladda.create(submitButton!);
  }

  updateListEmailAccounts() {
    // We need to get the list of available email accounts for logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/utils/misc/listEmailAccounts', options).then((response:any) => {
      if(response.accounts) {  
        this.listEMailAccounts = response.accounts;
      }
    });
  }

  sendEmail(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "account" : this.form.account,
      "subject" : this.form.subject,
      "to" : this.form.to,
      "cc" : this.form.cc,
      "bcc" : this.form.bcc,
      "content" : this.form.content
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/utils/misc/sendEmail', input, options).then((response:any) => {
      this.laddaSubmit!.stop();
    });
  }

}
</script>