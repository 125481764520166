<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h1>Check site</h1>
    </div>
    <div :class="'ibox-content p-md'">

      <form role="form" @submit="checkSite" name="checkSiteForm">
       
        <div class="form-group row">
          <label for="site" class="col-lg-3 col-form-label">Site</label>
          <div class="col-lg-9">
            <input id="site" type="text" placeholder="https://" class="form-control" v-model="form.site" required />
          </div>
        </div>

        <button class="btn btn-primary ladda-button submit" data-style="zoom-in" type="submit">Check</button>
        <button class="btn btn-primary ladda-button download" data-style="zoom-in" type="button" @click="downloadReference">Download Reference</button>

      </form>

    </div>
  </div>
        
</template>

<style scoped>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import * as api from '@fwk-client/utils/api';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';
import * as Ladda from 'ladda';

@Component({
  components: {}
})
export default class CheckSite extends Vue {

  form:any = {
    site: ""
  };

  laddaSubmit:Ladda.LaddaButton|null = null;
  laddaDownload:Ladda.LaddaButton|null = null;
  
  created() {
    
  }

  mounted() {
    var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=checkSiteForm] button.ladda-button.submit' );
    this.laddaSubmit = Ladda.create(submitButton!);

    var downloadButton:HTMLButtonElement|null = document.querySelector( 'form[name=checkSiteForm] button.ladda-button.download' );
    this.laddaDownload = Ladda.create(downloadButton!);
  }

  checkSite(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "site" : this.form.site
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/utils/misc/check-site/check', input, options).then((response:any) => {
      this.laddaSubmit!.stop();
    });
  }

  downloadReference(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "site" : this.form.site
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaDownload!.start();
    
    api.postAPI('/api/utils/misc/check-site/download-reference', input, options).then((response:any) => {
      this.laddaDownload!.stop();
    });
  }
  
}
</script>