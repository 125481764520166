<template>
  <section id="content">
    <PageTitle :title="$t('tools/misc.title')"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <div class="row">
        <div class="col-lg-12">
          <Email></Email>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <WhatsApp></WhatsApp>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <CheckSite></CheckSite>
        </div>
      </div>

    </div>

  </section>
</template>

<style scoped>
  
</style>


<script lang="ts">
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import PageTitle from '../../panels/PageTitle.vue';
import Email from '../../panels/tools/Email.vue';
import WhatsApp from '../../panels/tools/WhatsApp.vue';
import CheckSite from '../../panels/tools/CheckSite.vue';

@Component({
  components: {
    PageTitle,
    Email,
    WhatsApp,
    CheckSite
  }
})
export default class Misc extends mixins(GenericPage) {

}
</script>